/* About.css */

.section-1 {
    background-color: #fff; /* Bleu */
    padding: 20px;
    color: white;
}

.section-2 {
    background-color: #28a745; /* Vert */
    padding: 20px;
    color: white;
}

.section-3 {
    background-color: #dc3545; /* Rouge */
    padding: 20px;
    color: white;
}

.section-4 {
    background-color: #17a2b8; /* Cyan */
    padding: 20px;
    color: white;
}

.section-5 {
    background-color: #ffc107; /* Jaune */
    padding: 20px;
    color: black;
}

.section-6 {
    background-color: #6f42c1; /* Violet */
    padding: 20px;
    color: white;
}

.section-7 {
    background-color: #343a40; /* Gris foncé */
    padding: 20px;
    color: white;
}

h2 {
    font-size: 1em;
    margin-bottom: 20px;
}
p, ul{
    color: #0b2e13;
}
li.li-cuisson,li.li-poulet,li.li-prepa {

    padding: 1px;
    margin-left: 0px;
    font-size: 0.9em;
}
li.li-poulet::before {
    content: "🍗";
    position: relative;
    left: 0;
    font-size: 0.8em;
    margin-right: 15px;
}

li.li-cuisson::before {
    content: "🔥";
    position: relative;
    left: 0;
    font-size: 0.8em;
    margin-right: 15px;
}

li.li-prepa::before {
    content: "🥘";
    position: relative;
    left: 0;
    font-size: 0.8em;
    margin-right: 15px;
}

.custom-card {
    border-radius: 15px; /* Coins arrondis */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre discrète */
    transition: all 0.3s ease-in-out; /* Transition douce pour l'effet hover */
}

.custom-card:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15); /* Ombre plus prononcée à l'hover */
}

/* Styles pour la carte du logo */
.logo-card {
    border: none; /* Suppression des bordures */
    box-shadow: none; /* Suppression de l'ombre */
}

.logo-img {
    max-width: 100px; /* Ajustez selon la taille de votre logo */
    height: auto;
    margin: 0 auto;
}

.logo-img {
    width: 150px; /* Ajustez la taille du logo si nécessaire */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition pour l'animation */
}

.logo-img:hover {
    transform: scale(1.1); /* Agrandissement léger au survol */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Ombre subtile au survol */
}