
html {
  height: 100%;
  margin: 0;
  background-color: #ffffff !important;
  font-family: "arial ";
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Bell Bottom Laser';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, p {
  font-family: Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
  color: #2E8B57; /* Rose rétro */
}
h6 {
}

span.dropDownLabel, .card-title{
  font-weight: bolder;
}
.container, .container-fluid {
  border-radius: 0;
  color:#333;
}


button {
  background-color: #ff3300; /* Rouge rétro */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

span.capitalize, mark.capitalize{
  text-transform:capitalize;
}

.search-icon {
  position: absolute;
  right: 16px;
  top: 31px;
  z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
}

li {

  padding: 0;
  margin-left: 0;
}

.suggestion-even {
  padding:0;
  background: #eee;
}

.suggestion-odd {
  padding:0;
  background: #000;
}

a.nav-link {
  color: #aaa; /* Rose rétro */
}
div.dropdown-menu {
 border:none;
}
a.card-link{
    color: #111; /* Rose rétro */
  text-align: right;
}

div.card-body{
  border-radius: 44px ;
}

div.card{
  border:none ;
  background: #fff;
}

.btn-home {
  color: #333;
  background-color: transparent;
  border:1px solid #28a745;
}

.card-pressed {
  transform: scale(0.95); /* Réduit légèrement la taille de l'élément */
  transition: transform 0.1s ease-in-out; /* Animation de retour */
}

.card-unpressed {
  transform: scale(1); /* Retour à la taille normale */
  transition: transform 0.1s ease-in-out; /* Animation de retour */
}

@media (max-width: 576px) {
  .dropDownLabel {
    font-size: 1.5rem; /* Agrandir le titre sur mobile */
  }
}

/* Animation d'apparition/disparition de la modal */
.modal-closing .modal-dialog {
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

/* Animation pour la fermeture de la carte */
.map-closing {
  animation: slideOutDown 0.3s forwards;
}

@keyframes slideOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* Animation d'apparition/disparition de la modal */
.modal-closing .modal-dialog {
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

/* Animation pour la fermeture de la carte */
.map-closing {
  animation: slideOutDown 0.3s forwards;
}

@keyframes slideOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}







