.sidebar-container {
    position: absolute; /* Positionné en absolu pour s'intégrer à la bannière */
    top: 20px; /* Ajustez la position verticale selon vos besoins */
    left: 20px; /* Ajustez la position horizontale selon vos besoins */
    z-index: 1000; /* Assurez-vous que le bouton est au-dessus des autres éléments */
}

.burger-button {
    background: transparent; /* Fond transparent pour le bouton burger */
    border: none; /* Pas de bordure */
    cursor: pointer; /* Curseur pointeur pour l'interaction */
    display: flex; /* Utiliser flexbox pour centrer les lignes */
    flex-direction: column; /* Alignement vertical des lignes */
    justify-content: center; /* Centrer verticalement */
    align-items: center; /* Centrer horizontalement */
    width: 40px; /* Largeur du bouton burger */
    height: 40px; /* Hauteur du bouton burger */
}

.line {
    width: 30px; /* Largeur des lignes du burger */
    height: 4px; /* Épaissir légèrement les lignes */
    background-color: white; /* Couleur des lignes par défaut */
    margin: 3px 0; /* Espacement entre les lignes */
    border-radius: 2px; /* Ajout de bords arrondis */
    transition: 0.4s; /* Transition douce pour l'effet d'ouverture */
}

/* Styles pour le menu */
.sidebar-menu {
    position: fixed; /* Fixe le menu par rapport à la fenêtre */
    top: 0; /* Commence en haut */
    left: 0; /* Aligne à gauche */
    height: 100%; /* Prend toute la hauteur de la fenêtre */
    width: 75%; /* Prend 75% de la largeur de l'écran */
    max-width: 300px; /* Largeur maximale du menu */
    background-color: rgba(0, 0, 0, 0.9); /* Fond noir semi-transparent */
    color: white; /* Couleur du texte */
    border-radius: 5px; /* Arrondir les coins */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Ombre portée */
    padding: 20px; /* Ajouter du padding autour du menu */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Transition pour un effet doux */
    transform: translateX(-100%); /* Position hors écran par défaut */
    opacity: 0; /* Opacité à zéro pour le masquage */
    pointer-events: none; /* Désactiver les événements lorsque le menu est fermé */
    z-index: 1001; /* Assurez-vous que le menu est au-dessus des autres éléments */
}

.sidebar-menu.open {
    transform: translateX(0); /* Fait glisser le menu à droite lorsqu'il est ouvert */
    opacity: 1; /* Opacité à 1 pour le rendre visible */
    pointer-events: auto; /* Réactiver les événements lorsque le menu est ouvert */
}

.sidebar-menu ul {
    list-style-type: none; /* Pas de puces pour la liste */
    padding: 0; /* Pas de padding */
}

.sidebar-menu li {
    margin: 10px 0; /* Espacement entre les éléments de la liste */
}

.sidebar-menu a {
    font-size: 14px;
    text-decoration: none; /* Pas de soulignement pour les liens */
    color: white; /* Couleur des liens */
    transition: color 0.2s ease; /* Transition pour le changement de couleur */
}

.sidebar-menu a:hover {
    color: #ccc; /* Couleur au survol */
}

/* Styles pour l'état ouvert du bouton burger */
.burger-button .line.open {
    background-color: white; /* Couleur des lignes quand le menu est ouvert */
}

.burger-button .line.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px); /* Rotation de la première ligne */
}

.burger-button .line.open:nth-child(2) {
    opacity: 0; /* Masque la deuxième ligne */
}

.burger-button .line.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px); /* Rotation de la troisième ligne */
}
.close-button {
    background: transparent; /* Fond transparent pour le bouton de fermeture */
    border: none; /* Pas de bordure */
    color: white; /* Couleur blanche pour la croix */
    font-size: 24px; /* Taille de la croix */
    position: absolute; /* Positionnement absolu */
    right: 20px; /* Aligner à droite */
    top: 20px; /* Aligner en haut */
    cursor: pointer; /* Curseur pointeur pour l'interaction */
}

