/* CSS pour le style de la bannière */

.cookie-consent-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Arrière-plan semi-transparent */
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    z-index: 1000;
    font-size: 0.6rem;
}

.cookie-consent-banner {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-height: 80%; /* Ajustez selon vos besoins pour la hauteur max */
    overflow-y: auto; /* Permet le défilement vertical si le contenu dépasse la hauteur */
    z-index: 1000; /* Assure que la bannière soit au-dessus des autres éléments */
    border-radius: 5px; /* Coins arrondis pour un look moderne */
}

/* Style pour le texte et les éléments de la bannière */
.cookie-consent-banner h4 {
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.cookie-consent-banner p {
    margin-bottom: 20px;
    font-size: 0.6rem;
}

.cookie-consent-banner ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.cookie-consent-banner li {
    margin-bottom: 10px;
}

.cookie-consent-banner input {
    margin-right: 10px;
}

/* Style pour le bouton d'enregistrement */
.cookie-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%; /* Prend toute la largeur */
}

.cookie-btn:hover {
    background-color: #45a049;
}

/* Style pour le bouton de modification des préférences, discret comme un lien, en bas à gauche */
.modify-cookie-btn {
    position: absolute; /* Position relative à la bannière */
    bottom: 10px; /* Distance par rapport au bas de la bannière */
    left: 10px;   /* Distance par rapport au bord gauche de la bannière */
    background: none;
    border: none;
    color: #afafaf; /* Couleur de lien */
    text-decoration: none;
    font-size: 0.9rem; /* Taille de police plus petite */
    cursor: pointer;
    z-index: 1000;
}

.modify-cookie-btn:hover {
    color: #afafaf; /* Couleur plus foncée au survol */
    text-decoration: underline; /* Retirer le soulignement au survol */
}
