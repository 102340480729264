/* src/Components/LoadingOverlay.css */

.overlay {
    position: fixed; /* Positionner l'overlay au-dessus du contenu */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1); /* Couleur de fond semi-transparente */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Assurez-vous que l'overlay est au-dessus des autres éléments */
}

.loading-spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid #007bff; /* Couleur de la bordure supérieure */
    border-radius: 50%;
    width: 50px; /* Taille du spinner */
    height: 50px; /* Taille du spinner */
    animation: spin 1s linear infinite; /* Animation de rotation */
}

.loading-text {
    margin-top: 10px; /* Espace entre le spinner et le texte */
    font-size: 18px; /* Taille de la police */
    color: #ffffff; /* Couleur du texte */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
