
body,
html {
  height: 100%;
  font-family: "arial ";
}
#collasible-nav-dropdown > a,
a.dropdown-item {
  font-size: 1em;
}
.main-content {
  background-color: #fff;
}
.navbar {
  background-color: #000 !important;
}

.card-header {
  font-size: 1.5em;
}
h2.grid-categorie {
  font-size: 1.2em;
  background-color: #000;
  color: #efefef;
  padding: 10px;
  margin-top: 10px;
}


body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0px;
}

.card {
  border-radius: 10px; /* Coins arrondis */
  padding: 20px;
  max-width: 400px; /* Largeur maximale */
  margin: auto; /* Centrer la carte */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Ombre douce */
  transition: transform 0.2s; /* Animation au survol */
}

.card:hover {

}

.card h1 {
  font-size: 24px; /* Taille du titre */
  margin: 0 0 10px 0; /* Marge inférieure */
  text-align: center; /* Centrer le texte */
}

.card .info {
  display: flex;
  justify-content: space-around; /* Espacement égal */
  font-size: 14px; /* Taille de police pour les informations */
  color: #555; /* Couleur du texte des infos */
}

.card .info span {
  display: flex;
  align-items: center; /* Aligner les icônes */
}

.card .info i {
  margin-right: 5px; /* Espacement entre l'icône et le texte */
}

.card p {
  margin: 15px 0; /* Marges supérieure et inférieure */
  line-height: 1.6; /* Hauteur de ligne */
  color: #333; /* Couleur du texte */
}

.card .btn {
  display: block; /* Prendre toute la largeur */
  color: black; /* Couleur du texte */
  text-align: center; /* Centrer le texte */
  padding: 10px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis du bouton */
  text-decoration: underline; /* Enlever le soulignement */
  transition: background-color 0.3s; /* Animation de couleur */
  border: 1px solid darkgreen; /* Bordure du bouton */
}

.card .btn:hover {
  background-color: #FFA500; /* Couleur du bouton au survol */
}

