button {
    padding: 10px 20px;
    background-color: transparent;
    color: #444;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#fallbackShare a {
    display: block;
    margin: 10px 0;
    text-decoration: none;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
}

p {
    font-size: 16px;
    margin-top: 10px;
}

p.success {
    color: green;
}

p.error {
    color: red;
}
