/* Animation de rotation pour le logo */
@keyframes logoSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Fond blanc transparent */
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideIn 0.7s ease-out; /* Animation de glissement */
}

.overlay-logo {
    width: 70px; /* Taille du logo, ajustable */
    height: 70px;
    animation: logoSpin 1s linear; /* Animation de rotation */
}
